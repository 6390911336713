<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xxl-8" v-if="profile">
    
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
          >
            <img :src="'https://api.ytmdanismanlik.com/'+profile.profileImageDirectory+profile.profileImage" alt="image" />
            <div
              class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
            ></div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <span class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{profile.name+ ' '+profile.surname}}</span>
                <span>({{genders[profile.gender]}}, {{Math.round(moment().diff(profile.birthday,'years',true))}} Yaş)</span>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                  <span class="d-flex align-items-center justify-content-center">
                     <country-flag :country="profile.countryISO" size='small' /> 
                     <span>{{profile.country}}</span>
                  </span>
                </a>
                <a
                  href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                  {{profile.city}}
                </a>

                <a
                  href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5  mb-2">
                  +{{profile.prefix}}{{profile.phone}}
                </a>
                <a
                  href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                  {{profile.email}}
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

           
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-2 fw-bolder">
                      {{profile.height}}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Boy</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-2 fw-bolder">
                      {{profile.weight}}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Kilo</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-2 fw-bolder">
                      {{profile.score}}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Puanı </div>
                  
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                

                
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Progress-->
            
            <!--end::Progress-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <!--begin::Navs-->
      <div class="d-flex overflow-auto h-55px" v-if="tabs">
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">          
          <li class="nav-item" v-for="(tab, i) in tabs" :key="i">
            <router-link
              :to="tab.route.replace('{user_id}',profile.userId)"
              class="nav-link text-active-primary me-6"
              active-class="active" v-if="isAdmin || (!isAdmin && !tab.hiddeNoAdmin)">
             {{tab.title}}
             
             
            </router-link>
          </li>
          
          
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <!--end::Navbar-->
  <router-view v-if="profile" :isAdmin="isAdmin" :profile="profile"></router-view>
</template>

<script>
import { defineComponent } from "vue";

import api from '@/core/services/ApiService'
import CountryFlag from 'vue-country-flag-next'
import moment from 'moment'
import labels from "@/core/config/labels";
import JwtService from '@/core/services/JwtService'



export default defineComponent({
  name: "profile",
  components: {
    CountryFlag
  },
  data(){

    

    return {
      isAdmin:false,
      tabs:
        [
          {
            route:'/client/{user_id}/calls',
            title:'Görüşmeler'
          },
          /* {
            route:'/client/{user_id}/activities',
            title:'Aktiviteler'
          }, */
          {
            route:'/client/{user_id}/notes',
            title:'Notlar'
          },
          {
            route:'/client/{user_id}/purchases',
            title:'Satın Almalar'
          },
          {
            route:'/client/{user_id}/group-logs',
            title:'Kullanıcı grubu logları'
          },
          {
            route:'/client/{user_id}/packages',
            title:'Sahip olduğu paketler'
          },
          {
            route:'/client/{user_id}/files',
            title:'Dosyalar'
          },
          {
            route:'/client/{user_id}/settings',
            title:'Ayarlar',
            hiddeNoAdmin:true,
          },
        ],
      

      profile:null,
      genders:labels.genders
    }
  },
  
  methods:{
    moment(date){
      return moment(date);
    }
  },
  mounted(){
     
    let user_id = this.$route.params.user_id;
    api.get('/User/GetUser/'+user_id).then((res)=>{
       
      this.profile = res.data;
      this.isAdmin = JwtService.isAdmin();
    });

  }
});
</script>
